import React, { useState } from 'react'
import {
    Box,
    Flex,
    Input,
    Heading,
    Text
} from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

export default function MostUniqueForm({ x, formData, setFormData }) {

    return (
        <AnimatePresence>
        <Box w="100%" as={motion.div}
           initial={{
            x : x,
            opacity: 0
        }}
        animate={{ 
            x: 0,
            opacity: 1,
            transition: { x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 } }}}
        // exit={{x: '-100%',
        //     opacity: 0,
        //     transition: { duration: 0.3 }}}
        >
            <Heading textAlign="center">
                Please enter the following:
            </Heading>
            <Flex w="100%" flexWrap="wrap">
                <Box w={{base: "100%", md: "20%"}} p="2">
                    <Text mb='8px'>Your Home Zip Code*</Text>
                    <Input
                        required
                        name="zip_code"
                        id="zip_code"
                        type='number'
                        value={formData.zip_code}
                        onChange={(e) => {
                            setFormData({
                            ...formData,
                            zip_code: e.target.value,
                            });
                        }}
                        placeholder='83422'
                        size='lg'
                    />
                </Box>
                <Box w={{base: "100%", md: "40%"}} p="2">
                    <Text mb='8px'>Your email address</Text>
                    <Input
                        type='email'
                        value={formData.email}
                        onChange={(e) => {
                            setFormData({
                            ...formData,
                            email: e.target.value,
                            });
                        }}
                        placeholder='name@address.com'
                        size='lg'
                    />
                </Box>
                <Box w={{base: "100%", md: "40%"}} p="2">
                    <Text mb='8px'>If you are from out of town, how many nights are you staying?</Text>
                    <Input
                        type='number'
                        value={formData.out_of_town}
                        onChange={(e) => {
                            setFormData({
                            ...formData,
                            out_of_town: e.target.value,
                            });
                        }}
                        placeholder='1'
                        size='lg'
                    />
                </Box>
            </Flex> 
        </Box>
        </AnimatePresence>
    )
}