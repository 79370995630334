import React from 'react'
import {
    Box,
    Flex,
    Button,
    Heading,
    Text
} from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

export default function PeoplesChoiceForm({ scarecrows, formData, setFormData, x }) {
    const sculptures = scarecrows.scarecrow;
    console.log(sculptures)
    
    return (
        <AnimatePresence>
            <Box w="100%" as={motion.div} 
                initial={{
                    x : x,
                    opacity: 0
                }}
                animate={{ 
                    x: 0,
                    opacity: 1,
                    transition: { x: { type: "spring", stiffness: 300, damping: 30 },
                    opacity: { duration: 0.2 } }}}
                >
                <Heading textAlign="center">
                    People's Choice: Vote for the the best snow sculpture.
                </Heading>
                
                <Flex w="100%" flexWrap="wrap">
                    {sculptures.map((item, index) => {
                        return (
                            <Box w={{base: "100%", md: "50%", lg: "16.66%"}} p="2" key={index}>
                            <input type="radio" 
                                name="most_creative"
                                value={item.scuplture_id}
                                id={item.scuplture_id}
                                checked={formData.peoples_choice === item.scuplture_id}
                                onChange={(e) => {
                                    setFormData({
                                    ...formData,
                                    peoples_choice: e.target.value,
                                    });
                                }}
                            />
                            
                            <label htmlFor={item.scuplture_id}>
                                <Flex flexDirection="column" boxShadow="md" p="4" borderRadius="md" height="100%" justifyContent="space-between" cursor="pointer">
                                    <Heading as="h4" size="lg" mb="2" textAlign="center">
                                        {item.scuplture_id}
                                    </Heading>
                                    <Box mb="2">
                                        <GatsbyImage image={getImage(item.sculpture_image.localFile)} alt={item.sculpture_title} />
                                    </Box>
                                    <Box textAlign="center" mt="auto">
                                        <Text fontWeight="900">{item.sculpture_title}</Text>
                                        <Text mb="0">Team:</Text>
                                        <PrismicRichText field={item.sculpture_description.richText} />
                                    </Box>
                                    
                                </Flex>
                            </label>
                        </Box>
                        )
                    })
                    }
                </Flex>
            </Box>
        </AnimatePresence>
    )
}