import React, { useState } from 'react'
import { useForm } from "react-hook-form"
import {
    Box,
    Button,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import PeoplesChoiceForm from './PeoplesChoiceForm'
import DemographicsForm from './DemographicsForm'
import KidsFavoriteForm from './KidsFavoriteForm'

export default function SnowSculptureForm({ snowSculptures, zipCode }) {
    const [page, setPage] = useState(0);
    const [formData, setFormData] = useState({
        zip_code: '',
        email: '',
        out_of_town: '',
        most_creative: '',
        most_unique: '',
        kids_favorite: ''
    });
    const [x, setX] = useState('0%');
    const [succeeded, setSucceeded] = useState(false);
    const [processing, setProcessing] = useState(false);

    const {
        handleSubmit,
        reset,
        formState: { isValid, errors },
      } = useForm({
        mode: "onSubmit",
      })

    console.log(formData)

    const ConditionalComponent = () => {
        switch (page) {
            case 0:
                return <DemographicsForm snowSculptures={snowSculptures} formData={formData} setFormData={setFormData} page={page} x={x} setX={setX} zipCode={zipCode} />;
            case 1: 
                return <PeoplesChoiceForm snowSculptures={snowSculptures} formData={formData} setFormData={setFormData} page={page} x={x} setX={setX} />;
            case 2:
                return <KidsFavoriteForm snowSculptures={snowSculptures} formData={formData} setFormData={setFormData} page={page} x={x} setX={setX} />;
            default:
                return <DemographicsForm snowSculptures={snowSculptures} formData={formData} setFormData={setFormData} page={page} x={x} setX={setX} />;
        }
    }

    const SubmitButton = () => (
        <Button variant="snowscapes" type="submit" size="lg" disabled={processing}>
            {processing ? ('Submitting...') : ('Submit')}
        </Button>
    )
    const SuccessMessage = () => (
        <Box>
        <Alert status="success"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
          borderRadius="1rem"
          >
        <AlertIcon boxSize='40px' mr={0}/>
            <AlertTitle mb="4">
                Thank you for voting!
            </AlertTitle>
            <AlertDescription maxWidth='md'>
                The winners will be announced via social media Sunday, January 22.
            </AlertDescription>
            </Alert>
        </Box>
      )

    const onSubmit = data => {
    
        setProcessing(true);

        // Convert state object to FormData object as required by the Apps Script
        function getFormData(object) {
            const newFormData = new FormData();
            Object.keys(object).forEach(key => newFormData.append(key, object[key]));
            return newFormData;
        }
        let googleData = getFormData(formData)

        //console.log(googleData)

    fetch(`https://script.google.com/macros/s/AKfycbw3XCay7yqQ-CEyJo4yNdtc4UpBTNxYEtcpL2yiXpIpEb6FKd4KiEQ0ppFZopojyBfi/exec`,
    {
        method: "POST",
        body: googleData,
    }).then((response) => {
        if (response.status === 200) {
          //console.log(new FormData(votingData))
          setProcessing(false);
          setSucceeded(true);
          reset();
        } else {
            console.log('Error submiting to Google Sheet')
        }
    })
        .catch((error) => console.log('Google error!', error.message))      
    setTimeout((data) => { 
    }, 4000)
    }



    return (
        // overflowx hidden to prevent framer motion from causing horizontal scroll
        <Box overflowX="hidden">
            <Box mb="8">
            { succeeded 
             ? <SuccessMessage /> 
             : (
                <form onSubmit={handleSubmit(onSubmit)} id="snowscapes-form" name="snowscapes-form">
                    {ConditionalComponent()}
                    <Box textAlign="right" mt="8">
                        { page > 0 && (
                            <Button variant="snowscapes" onClick={() => { setPage(page - 1); setX('-100%')} } mx="2" size="lg"><ChevronLeftIcon ml="-2" /> Back</Button>
                        )}
                        {page === 2 
                            ? (<SubmitButton /> )
                            : (<Button variant="snowscapes" onClick={() => { setPage(page + 1); setX('100%')} } mx="2" size="lg" disabled={!formData.zip_code}>
                                <span>Next</span> <ChevronRightIcon mr="-2"/>
                            </Button>)
                        }
                        
                    </Box>
                </form>
            )}
            </Box>
        </Box>
    )
}